import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './plugins/sentry';
import './plugins/logrocket';
import './plugins/i18n';
import './plugins/mixpanel';
import Router from './router';

import ToasterPlugin from './plugins/toaster';
import ReduxProvider from './providers/redux';
import LayoutError from './pages/layout/Error';
import NotificationProvider from './providers/notification';
import MessageProvider from './providers/message';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ReduxProvider>
      <NotificationProvider>
        <ToasterPlugin />
        <LayoutError>
          <MessageProvider>
            <Router />
          </MessageProvider>
        </LayoutError>
      </NotificationProvider>
    </ReduxProvider>
  </React.StrictMode>
);
