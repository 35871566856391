import { combineReducers } from '@reduxjs/toolkit';
import registerFormReducer from './slices/register/form';
import authReducer from './slices/auth';
import onboardingReducer from './slices/onboarding';

import { authRegisterApi } from '@/api/auth/register';
import { authLoginApi } from '@/api/auth/login';
import { authUserApi } from '@/api/auth/user';
import { vesselApi } from '@/api/vessel';
import { jobsApi } from '@/api/jobs';
import { rankApi } from '@/api/rank';
import { masterDataApi } from '@/api/masterData';
import { jobsApplicationApi } from '@/api/jobs/application';
import { crewApi } from '@/api/crew';
import { adminApi } from '@/api/admin';
import { roleApi } from '@/api/role';
import { dashboardApi } from '@/api/dashboard';
import { companyApi } from '@/api/company';
import { rateApi } from '@/api/rate';
import { notificationApi } from '@/api/notification';
import { downloadCertificatesApi } from '@/api/download/certificates';
import { downloadDocumentsApi } from '@/api/download/documents';
import { fileApi } from '@/api/file';
import { conversationApi } from '@/api/conversation';
import { documentApi } from '@/api/document';
import { certificateApi } from '@/api/certificate';

const reducer = combineReducers({
  auth: authReducer,
  onboarding: onboardingReducer,
  registerForm: registerFormReducer,

  [authUserApi.reducerPath]: authUserApi.reducer,
  [authRegisterApi.reducerPath]: authRegisterApi.reducer,
  [authLoginApi.reducerPath]: authLoginApi.reducer,
  [vesselApi.reducerPath]: vesselApi.reducer,
  [jobsApi.reducerPath]: jobsApi.reducer,
  [jobsApplicationApi.reducerPath]: jobsApplicationApi.reducer,
  [rankApi.reducerPath]: rankApi.reducer,
  [masterDataApi.reducerPath]: masterDataApi.reducer,
  [crewApi.reducerPath]: crewApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [rateApi.reducerPath]: rateApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [downloadCertificatesApi.reducerPath]: downloadCertificatesApi.reducer,
  [downloadDocumentsApi.reducerPath]: downloadDocumentsApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [conversationApi.reducerPath]: conversationApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [certificateApi.reducerPath]: certificateApi.reducer,
});

export default reducer;
