import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { PAGINATION_LIMIT } from '@/constants/pagination';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IRequestParamGetCrewList,
  IResponseCrewList,
  IResponseGetCrewAuditLog,
  IRequestCreateAuditLog,
  IReturnCrewList,
  IReturnGetCrewAuditLog,
  TReturnCrewEducationList,
  IRequestParamGetContactedCrewList,
  IResponseContactedCrewList,
  IReturnContactedCrewList,
  IReturnGetOfferJob,
  IResponseGetOfferJob,
  IRequestOfferJob,
  IReturnGetCrewRatingLog,
  IReturnRateStatistic,
  IResponseGetCrewRatingLog,
  TResponseGetCrewStatusList,
  IRequestGetMyCrewList,
  IResponseGetMyCrewList,
  IReturnGetMyCrewList,
  IReturnGetCompanyCrewContractDetail,
  IRequestSubmitManageContractForm,
  IResponseGetCrewDetail,
  IReturnGetCrewDetail,
  IRequestGetCrewContractHistoryList,
  TReturnGetCrewContractHistoryList,
  IRequestGetCompanyCrewPersonalInfo,
  IReturnGetCompanyCrewPersonalInfo,
  IRequestEditCompanyCrewPersonalInfo,
  IResponseGetCompanyCrewPersonalInfo,
  IRequestGetCompanyCrewExperience,
  IReturnGetCompanyCrewDocument,
  IRequestGetCompanyCrewDocument,
  IReturnGetCompanyCrewAdditionalAttachment,
  IRequestGetCompanyCrewAdditionalAttachment,
  IReturnGetCompanyCrewCertificate,
  IRequestGetCompanyCrewCertificate,
  IReturnGetCompanyCrewExperience,
  IReturnImportCompanyCrew,
  IRequestImportCompanyCrew,
  IReturnDeleteCompanyCrew,
  IRequestDeleteCompanyCrew,
  IRequestGetCompanyCrewInvitation,
  IRequestPostCompanyCrewInvitation,
  TReturnPostCompanyCrewInvitation,
  IReturnGetCompanyCrewInvitation,
  IResponseGetCompanyCrewInvitation,
  IRequestTerminateCrew,
  IRequestCrewAvailability,
  IReturnCrewAvailability,
  IRequestSubmitCreateContractForm,
} from './type';
import { rtkInfiniteScrollConfig } from '@/config/api/infiniteScroll';
import {
  convertObjectToQueryParams,
  convertApplicantStatusValueToLabel,
} from '@/helpers/format/api';
import { TQuestionType } from '@/constants/question';
import { IDataDropdown } from '@/interfaces/dropdown';
import { getGenderValueFromResponse } from '@/helpers/gender';

export const crewApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'crewApi',
  tagTypes: [
    'CREW',
    'AUDIT_LOG',
    'CREW_OFFER',
    'CONTACTED_CREW',
    'MY_CREW',
    'GET_MY_CREW_INVITATION_DETAIL',
    'RATE_STATISTIC',
    'GET_MY_CREW_CERTIFICATE',
    'GET_MY_CREW_DOCUMENT',
    'GET_MY_CREW_ADDITIONAL_ATTACHMENT',
    'GET_MY_CREW_CONTRACT_HISTORY',
    'COMPANY_CREW_PERSONAL_INFO',
    'COMPANY_CREW_EXPERIENCE',
    'GET_COMPANY_CREW_CONTRACT_DETAIL',
  ],
  endpoints: builder => ({
    getAuditLogMyCrew: builder.query<
      IReturnGetCrewAuditLog,
      { assignmentId?: string; page: number }
    >({
      query: ({ assignmentId, page }) => ({
        url: `v1/company/company-assignment/${assignmentId}/audit-log`,
        method: 'GET',
        params: {
          page,
          limit: PAGINATION_LIMIT,
        },
      }),
      providesTags: ['AUDIT_LOG'],
      transformResponse: (response: IResponseGetCrewAuditLog) => {
        return {
          pagination: response.meta,
          list: response.items || [],
        };
      },
    }),
    getAuditLogCrew: builder.query<
      IReturnGetCrewAuditLog,
      { crewId?: string | null; page: number }
    >({
      query: ({ crewId, page }) => ({
        url: `/v1/company/audit-log/${crewId}`,
        method: 'GET',
        params: {
          page,
          limit: PAGINATION_LIMIT,
        },
      }),
      providesTags: ['AUDIT_LOG'],
      transformResponse: (response: IResponseGetCrewAuditLog) => {
        return {
          pagination: response.meta,
          list: response.items || [],
        };
      },
    }),
    getCrewRateLog: builder.query<IReturnGetCrewRatingLog, { id: string; page: number }>({
      query: ({ id, page }) => ({
        url: `v1/company-crew/crew/${id}/rate`,
        method: 'GET',
        params: {
          page,
          limit: PAGINATION_LIMIT,
        },
      }),
      providesTags: ['AUDIT_LOG'],
      transformResponse: (response: IResponseGetCrewRatingLog) => {
        return {
          pagination: response.meta,
          list: response.items || [],
        };
      },
    }),
    getRateStatisticData: builder.query<IReturnRateStatistic, { crewId?: string }>({
      query: ({ crewId }) => ({
        url: `v1/company-crew/crew/${crewId}/rate/statistic`,
        method: 'GET',
      }),
      providesTags: ['RATE_STATISTIC'],
    }),
    createAuditLog: builder.mutation<void, IRequestCreateAuditLog>({
      query: data => ({
        url: `/v1/company/audit-log/note`,
        method: 'POST',
        body: data,
      }),
    }),

    getCrewList: builder.query<IReturnCrewList, IRequestParamGetCrewList>({
      query: param => {
        const params = convertObjectToQueryParams(param);

        return {
          url: '/v1/company/crew-registered' + '?' + params.toString(),
        };
      },
      transformResponse: (response: IResponseCrewList) => {
        return {
          pagination: response.meta,
          list: response.items || [],
        };
      },
      merge: rtkInfiniteScrollConfig.merge,
      serializeQueryArgs: rtkInfiniteScrollConfig.serializeQueryArgs,
      forceRefetch: rtkInfiniteScrollConfig.forceRefetch,
    }),
    getCrewDetail: builder.query<IReturnGetCrewDetail, { id: string }>({
      providesTags: ['CREW'],
      query: ({ id }) => ({
        url: `/v1/company/crew-registered/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: IResponseGetCrewDetail) => {
        return response;
      },
    }),

    getCrewEducation: builder.query<TReturnCrewEducationList, { crewId?: string }>({
      providesTags: ['CREW'],
      query: ({ crewId }) => ({
        url: `/v1/company/crew/${crewId}/education`,
        method: 'GET',
      }),
    }),

    getContactedCrewList: builder.query<
      IReturnContactedCrewList,
      IRequestParamGetContactedCrewList
    >({
      providesTags: ['CONTACTED_CREW'],
      query: param => {
        const params = convertObjectToQueryParams(param);

        return {
          url: '/v1/company/offered-crew' + '?' + params.toString(),
        };
      },
      transformResponse: (response: IResponseContactedCrewList) => {
        return {
          pagination: response.meta,
          list: response.items || [],
        };
      },
      merge: rtkInfiniteScrollConfig.merge,
      serializeQueryArgs: rtkInfiniteScrollConfig.serializeQueryArgs,
      forceRefetch: rtkInfiniteScrollConfig.forceRefetch,
    }),

    getOfferJob: builder.query<IReturnGetOfferJob | undefined, { crewId?: string }>({
      providesTags: ['CREW_OFFER'],
      query: ({ crewId }) => ({
        url: `/v1/company/crew/${crewId}/offer`,
        method: 'GET',
      }),
      transformResponse: (response: IResponseGetOfferJob) => {
        const { data, canOffer } = response;

        if (!data) {
          return {
            canOffer: canOffer,
          };
        }

        return {
          jobId: data.jobId,
          title: data.title,
          vesselType: {
            id: data.vesselType.id,
            value: data.vesselType.value,
            label: data.vesselType.label,
          },
          vesselFlag: data.vesselFlag,
          contractDuration: {
            year: data.contractDuration.year,
            month: data.contractDuration.month,
          },
          message: data.message,
          rank: {
            value: data.rank.value,
            label: data.rank.label,
          },
          isRotational: data.isRotational,
          salaryCurrency: data.salaryCurrency,
          minSalary: data.minSalary,
          maxSalary: data.maxSalary,
          offeredBy: data.offeredBy,
          status: data.status,
          rejectionNote: data.rejectionNote,
          rejectType: data.rejectType,
          crewName: data.crewName,
          companyName: data.companyName,
          salaryOfferCount: data.salaryOfferCount,
          mandatoryCertificates: data.mandatoryCertificates || [],
          optionalCertificates: data.optionalCertificates || [],
          mandatoryDocuments: data.mandatoryDocuments || [],
          optionalDocuments: data.optionalDocuments || [],
          questions:
            data.questions?.map(el => ({
              id: el.id,
              question: el.question,
              type: el.type as TQuestionType,
              options:
                el.choices?.map(choice => ({
                  id: choice.id,
                  answer: choice.label,
                })) || [],
            })) || [],
          canOffer: canOffer,
        };
      },
    }),

    offerJob: builder.mutation<void, IRequestOfferJob>({
      query: data => ({
        url: `/v1/company/crew/${data.crewId}/offer`,
        method: 'POST',
        body: data.payload,
      }),
      invalidatesTags: ['CREW_OFFER'],
    }),

    editOfferJob: builder.mutation<void, IRequestOfferJob>({
      query: data => ({
        url: `/v1/company/crew/${data.crewId}/offer`,
        method: 'PATCH',
        body: data.payload,
      }),
      invalidatesTags: ['CREW_OFFER'],
    }),

    getCrewStatusList: builder.query<IDataDropdown[], void>({
      query: () => ({
        url: '/v1/master-data/crew-status',
        method: 'GET',
      }),
      transformResponse: (response: { data: TResponseGetCrewStatusList }) => {
        return response?.data.map(el => ({
          ...el,
          label: convertApplicantStatusValueToLabel(el.value),
        }));
      },
    }),

    /**
     * MyCrew: Get My Crew List
     */
    getMyCrewList: builder.query<IReturnGetMyCrewList, IRequestGetMyCrewList>({
      providesTags: ['MY_CREW'],
      query: param => {
        const params = convertObjectToQueryParams(param);

        return {
          url: '/v1/company-crew' + '?' + params.toString(),
        };
      },
      transformResponse: (response: IResponseGetMyCrewList) => {
        return {
          pagination: response.meta,
          list: response.items || [],
        };
      },
      merge: rtkInfiniteScrollConfig.merge,
      serializeQueryArgs: rtkInfiniteScrollConfig.serializeQueryArgs,
      forceRefetch: rtkInfiniteScrollConfig.forceRefetch,
    }),

    /**
     * MyCrew: Confirm Crew
     */
    getCompanyCrewContractDetail: builder.query<
      IReturnGetCompanyCrewContractDetail,
      { companyCrewContractId: string }
    >({
      query: payload => ({
        url: `/v1/company-crew/${payload.companyCrewContractId}`,
        method: 'GET',
      }),
      providesTags: ['GET_COMPANY_CREW_CONTRACT_DETAIL'],
    }),

    /**
     * MyCrew: Get Contract List
     */
    getCrewContractList: builder.query<
      TReturnGetCrewContractHistoryList,
      IRequestGetCrewContractHistoryList
    >({
      query: payload => ({
        url: `/v1/company/company-assignment/${payload.crewId}/contract`,
        method: 'GET',
      }),
      providesTags: ['GET_MY_CREW_CONTRACT_HISTORY'],
    }),

    /**
     * MyCrew: Get Crew Personal Info on My Crew
     */
    getCompanyCrewPersonalInfo: builder.query<
      IReturnGetCompanyCrewPersonalInfo,
      IRequestGetCompanyCrewPersonalInfo
    >({
      query: payload => ({
        url: `/v1/company/company-assignment/${payload.crewCompanyAssignmentId}/personal-info`,
        method: 'GET',
      }),
      transformResponse: (
        response: IResponseGetCompanyCrewPersonalInfo
      ): IReturnGetCompanyCrewPersonalInfo => ({
        ...response,
        gender: getGenderValueFromResponse(response.isMale),
      }),
      providesTags: ['COMPANY_CREW_PERSONAL_INFO'],
    }),

    /**
     * MyCrew: Get Crew Personal Info on My Crew
     */
    editCompanyCrewPersonalInfo: builder.mutation<void, IRequestEditCompanyCrewPersonalInfo>({
      query: ({ crewCompanyAssignmentId, data }) => ({
        url: `/v1/company/company-assignment/${crewCompanyAssignmentId}/personal-info`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['COMPANY_CREW_PERSONAL_INFO', 'CREW', 'MY_CREW', 'COMPANY_CREW_EXPERIENCE'],
    }),

    /**
     * MyCrew: Get Crew Experience
     */
    getCompanyCrewExperience: builder.query<
      IReturnGetCompanyCrewExperience,
      IRequestGetCompanyCrewExperience
    >({
      query: ({ crewCompanyAssignmentId }) => ({
        url: `/v1/company/company-assignment/${crewCompanyAssignmentId}/experience`,
        method: 'GET',
      }),
      providesTags: ['COMPANY_CREW_EXPERIENCE'],
    }),

    /**
     * MyCrew: Get Crew Document
     */
    getCompanyCrewDocument: builder.query<
      IReturnGetCompanyCrewDocument,
      IRequestGetCompanyCrewDocument
    >({
      query: ({ crewCompanyAssignmentId }) => ({
        url: `/v1/company/company-assignment/${crewCompanyAssignmentId}/document`,
        method: 'GET',
      }),
      providesTags: ['GET_MY_CREW_DOCUMENT'],
    }),

    /**
     * MyCrew: Get Crew Additional Attachment
     */
    getCompanyCrewAdditionalAttachment: builder.query<
      IReturnGetCompanyCrewAdditionalAttachment,
      IRequestGetCompanyCrewAdditionalAttachment
    >({
      query: ({ crewCompanyAssignmentId }) => ({
        url: `/v1/company/company-assignment/${crewCompanyAssignmentId}/additional-attachment`,
        method: 'GET',
      }),
      providesTags: ['GET_MY_CREW_ADDITIONAL_ATTACHMENT'],
    }),

    /**
     * MyCrew: Get Crew Certificate
     */
    getCompanyCrewCertificate: builder.query<
      IReturnGetCompanyCrewCertificate,
      IRequestGetCompanyCrewCertificate
    >({
      query: ({ crewCompanyAssignmentId }) => ({
        url: `/v1/company/company-assignment/${crewCompanyAssignmentId}/certificate`,
        method: 'GET',
      }),
      providesTags: ['GET_MY_CREW_CERTIFICATE'],
    }),

    /**
     * My Crew: Create Additional Attachment
     */
    createAdditionalAttachment: builder.mutation<void, FormData>({
      query: data => ({
        url: `/v1/company/attachment`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['GET_MY_CREW_ADDITIONAL_ATTACHMENT'],
    }),
    /**
     * My Crew: Edit Additional Attachment
     */
    editAdditionalAttachment: builder.mutation<void, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `/v1/company/attachment/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['GET_MY_CREW_ADDITIONAL_ATTACHMENT'],
    }),
    /**
     * My Crew: Delete Additional Attachment
     */
    deleteAdditionalAttachment: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/v1/company/attachment/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GET_MY_CREW_ADDITIONAL_ATTACHMENT'],
    }),

    /**
     * My Crew: Import Company Crew
     */
    importCompanyCrew: builder.mutation<IReturnImportCompanyCrew, IRequestImportCompanyCrew>({
      query: (data: IRequestImportCompanyCrew) => ({
        url: `/v1/company/crew`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['MY_CREW', 'COMPANY_CREW_PERSONAL_INFO', 'CREW', 'COMPANY_CREW_EXPERIENCE'],
    }),

    /**
     * My Crew: Delete Company Crew
     */
    deleteCompanyCrew: builder.mutation<IReturnDeleteCompanyCrew, IRequestDeleteCompanyCrew>({
      query: (data: IRequestDeleteCompanyCrew) => ({
        url: `/v1/company/company-assignment/${data.crewCompanyAssignmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MY_CREW', 'CREW', 'COMPANY_CREW_EXPERIENCE', 'COMPANY_CREW_EXPERIENCE'],
    }),

    /**
     * My Crew: Get Contract Invitation Detail (Prefilled)
     */
    getCompanyCrewInvitation: builder.query<
      IReturnGetCompanyCrewInvitation,
      IRequestGetCompanyCrewInvitation
    >({
      query: (payload: IRequestGetCompanyCrewInvitation) => ({
        url: `/v1/company/crew-assignment/${payload.crewCompanyAssignmentId}/invitation`,
      }),
      transformResponse: (response: IResponseGetCompanyCrewInvitation) => {
        return {
          rankId: response.rank.id,
          note: response.note || '',
          mandatoryCertificates: response.mandatoryCertificates || [],
          optionalCertificates: response.optionalCertificates || [],
          mandatoryDocuments: response.mandatoryDocuments || [],
          optionalDocuments: response.optionalDocuments || [],
          duration: {
            month: response.duration.month || 0,
            year: response.duration.year || 0,
          },
          isRotational: response.isRotational || false,
        };
      },
      providesTags: ['GET_MY_CREW_INVITATION_DETAIL'],
    }),

    /**
     * My Crew: Post/Create Invitation to Crew
     */
    postCompanyCrewInvitation: builder.mutation<
      TReturnPostCompanyCrewInvitation,
      IRequestPostCompanyCrewInvitation
    >({
      query: (payload: IRequestPostCompanyCrewInvitation) => ({
        url: `/v1/company/crew-assignment/${payload.crewCompanyAssignmentId}/invitation`,
        method: 'POST',
        body: payload.data,
      }),
      invalidatesTags: ['MY_CREW', 'COMPANY_CREW_PERSONAL_INFO', 'CREW', 'COMPANY_CREW_EXPERIENCE'],
    }),

    /**
     * My Crew: Create new Contract
     */

    createCompanyCrewNewContract: builder.mutation<
      void,
      { payload: IRequestSubmitCreateContractForm }
    >({
      query: ({ payload }) => ({
        url: `/v1/company/company-assignment/${payload.companyCrewAssignmentId}/contract`,
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: [
        'MY_CREW',
        'GET_MY_CREW_CONTRACT_HISTORY',
        'CREW',
        'COMPANY_CREW_PERSONAL_INFO',
        'COMPANY_CREW_EXPERIENCE',
        'GET_COMPANY_CREW_CONTRACT_DETAIL',
      ],
    }),

    /**
     * My Crew: manage Contract
     */

    patchCompanyCrewNewContract: builder.mutation<
      void,
      { payload: IRequestSubmitManageContractForm }
    >({
      query: ({ payload }) => ({
        url: `/v1/company-crew/${payload.id}/contract`,
        body: payload,
        method: 'PATCH',
      }),
      invalidatesTags: [
        'MY_CREW',
        'GET_MY_CREW_CONTRACT_HISTORY',
        'CREW',
        'COMPANY_CREW_PERSONAL_INFO',
        'COMPANY_CREW_EXPERIENCE',
        'GET_COMPANY_CREW_CONTRACT_DETAIL',
      ],
    }),

    terminateCrew: builder.mutation<void, IRequestTerminateCrew>({
      query: payload => ({
        url: '/v1/company-crew/terminate',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['MY_CREW', 'COMPANY_CREW_PERSONAL_INFO', 'CREW', 'COMPANY_CREW_EXPERIENCE'],
    }),

    getCrewAvailability: builder.query<IReturnCrewAvailability, IRequestCrewAvailability>({
      query: payload => ({
        url: `/v1/company/crew/${payload.crewId}/availability`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetCrewAvailabilityQuery,
  useTerminateCrewMutation,
  useGetCompanyCrewExperienceQuery,
  useGetCompanyCrewDocumentQuery,
  useGetCompanyCrewAdditionalAttachmentQuery,
  useGetCompanyCrewCertificateQuery,
  useLazyGetAuditLogMyCrewQuery,
  useLazyGetAuditLogCrewQuery,
  useLazyGetCrewRateLogQuery,
  useGetRateStatisticDataQuery,
  useLazyGetRateStatisticDataQuery,
  useCreateAuditLogMutation,
  useGetCrewListQuery,
  useGetCrewDetailQuery,
  useCreateAdditionalAttachmentMutation,
  useEditAdditionalAttachmentMutation,
  useDeleteAdditionalAttachmentMutation,
  useGetCrewEducationQuery,
  useGetContactedCrewListQuery,
  useGetOfferJobQuery,
  useOfferJobMutation,
  useEditOfferJobMutation,
  useGetCrewStatusListQuery,
  useGetMyCrewListQuery,
  useGetCompanyCrewContractDetailQuery,
  useGetCrewContractListQuery,
  useGetCompanyCrewPersonalInfoQuery,
  useEditCompanyCrewPersonalInfoMutation,
  useImportCompanyCrewMutation,
  useDeleteCompanyCrewMutation,
  useGetCompanyCrewInvitationQuery,
  usePostCompanyCrewInvitationMutation,
  useLazyGetCompanyCrewContractDetailQuery,
  usePatchCompanyCrewNewContractMutation,
  useCreateCompanyCrewNewContractMutation,
} = crewApi;
