import { createApi } from '@reduxjs/toolkit/query/react';
import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import {
  IGetNotificationPagination,
  INotificationRequestParams,
  IRequestReadNotification,
  IResponseNotification,
  IReturnNotificationCounter,
} from '@/api/notification/type';

export const notificationApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'notificationApi',
  tagTypes: ['NOTIFICATION', 'NOTIFICATION_HEADER', 'NOTIFICATION_COUNTER'],
  endpoints: builder => ({
    getNotification: builder.query<IGetNotificationPagination, INotificationRequestParams>({
      query: ({ page }) => ({
        url: '/v1/company/notification',
        method: 'GET',
        params: {
          page,
        },
      }),
      transformResponse: (response: IResponseNotification): IGetNotificationPagination => {
        return {
          ...response,
          items: response.items?.map(data => {
            return {
              message: data.message,
              type: data.type,
              jobId: data.jobID,
              crewId: data.crewID,
              isRead: data.isRead,
              image: data.image,
              createdAt: data.createdAt,
              id: data.id,
              companyVesselId: data.companyVesselID,
            };
          }),
        };
      },
      providesTags: ['NOTIFICATION'],
    }),
    getNotificationHeader: builder.query<IGetNotificationPagination, void>({
      query: () => ({
        url: '/v1/company/notification',
        method: 'GET',
      }),
      transformResponse: (response: IResponseNotification): IGetNotificationPagination => {
        return {
          ...response,
          items: response.items
            ?.map(data => {
              return {
                message: data.message,
                type: data.type,
                jobId: data.jobID,
                crewId: data.crewID,
                isRead: data.isRead,
                image: data.image,
                createdAt: data.createdAt,
                id: data.id,
                companyVesselId: data.companyVesselID,
              };
            })
            .slice(0, 3),
        };
      },
      providesTags: ['NOTIFICATION_HEADER'],
    }),
    getNotificationCounter: builder.query<IReturnNotificationCounter, void>({
      query: () => ({
        url: '/v1/company/notification/count',
        method: 'GET',
      }),
      providesTags: ['NOTIFICATION_COUNTER'],
    }),
    readNotification: builder.mutation<void, IRequestReadNotification>({
      query: payload => ({
        url: `/v1/company/notification/${payload.notificationId}/read`,
        method: 'PATCH',
      }),
      invalidatesTags: ['NOTIFICATION', 'NOTIFICATION_HEADER'],
    }),
  }),
});

export const {
  useGetNotificationQuery,
  useGetNotificationHeaderQuery,
  useLazyGetNotificationHeaderQuery,
  useGetNotificationCounterQuery,
  useReadNotificationMutation,
} = notificationApi;
