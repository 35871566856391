import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IResponseGetCompanyInfo,
  IResponseGetCompanyDetail,
  IRequestEditCompanyInfo,
  IRequestEditCompanyDetail,
} from './type';

export const companyApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'companyApi',
  tagTypes: ['company-info', 'company-detail'],
  endpoints: builder => ({
    getCompanyInfo: builder.query<IResponseGetCompanyInfo, void>({
      query: () => '/v1/company/info',
      providesTags: ['company-info'],
    }),
    getCompanyDetail: builder.query<IResponseGetCompanyDetail, void>({
      query: () => '/v1/company/detail',
      providesTags: ['company-detail'],
    }),
    editCompanyInfo: builder.mutation<IResponseGetCompanyInfo, IRequestEditCompanyInfo>({
      query: body => ({
        url: '/v1/company/info',
        method: 'PATCH',
        invalidatesTags: ['company-info'],
        body,
      }),
    }),
    editCompanyDetail: builder.mutation<IResponseGetCompanyInfo, IRequestEditCompanyDetail>({
      query: body => ({
        url: '/v1/company/detail',
        method: 'PATCH',
        invalidatesTags: ['company-detail'],
        body,
      }),
    }),
  }),
});

export const {
  useGetCompanyInfoQuery,
  useGetCompanyDetailQuery,
  useEditCompanyInfoMutation,
  useEditCompanyDetailMutation,
} = companyApi;
